import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
  import socketIOClient from "socket.io-client";
import { mainListItems, secondaryListItems } from './Components/listItems';
import Chart from './Components/Chart';
import Deposits from './Components/Deposits';
import Orders from './Components/Orders';
import moment from 'moment'
import ReactECharts from 'echarts-for-react';

import axios from 'axios';
import { connect } from "react-redux";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://govote.app/">
       Govote
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();



const graph = {
   grid: { top: 8, right: 8, bottom: 24, left: 36 },
   xAxis: {
     type: 'category',
     data:[] ,
   },
   yAxis: {
     type: 'value',
   },
   series: [
     {
       data: [] ,
       type: 'line',
       smooth: true,
     },
   ],
   tooltip: {
     trigger: 'axis',
   },
 };


class HomeView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
then:false,
active:0,
documents:[],
data:{  "votes": 250,
    "witnesses": 2,
    "wait": 0, graph:graph},
modal:true


    };

  this.socket = null;
  }



    componentDidMount (){

    this.getdata();

       this.socket = socketIOClient(this.props.url)


       this.socket.on(`/refresh` , (data) => {
          console.log("socket receive");
           this.getdata();
        })
    }




     getdata = () => {
       console.log("calling");
       this.setState({then: true});

           this.props.axios.get('/admin/home' )
         .then( response => {


           this.setState({then: false});
         this.setState({data: response.data})



    this.setState({then: false});
         })
           .catch( error => {
           //*.log(error);
             this.setState({then: false});

         });




     };




  render() {

  return (

    <>


    <Container maxWidth="lg" sx={{ mt: 3, mb: 3 }}>

      <Grid container spacing={3}>
        {/* Chart */}

        {/* Recent Deposits */}

        <Grid item xs={12} md={4} lg={3}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 150,
            }}
          >
            <Deposits title={"Votes"} amount={this.state.data.votes} />
          </Paper>
        </Grid>



        <Grid item xs={12} md={4} lg={3}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 150,
            }}
          >
            <Deposits title={"Pv reçu"} amount={this.state.data.witnesses} />
          </Paper>
        </Grid>

        

        <Grid item xs={12} md={4} lg={3}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 150,
            }}
          >
            <Deposits title={"En attente"} amount={this.state.data.usersTotal} />
          </Paper>
        </Grid>














        {/* Recent Orders */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <h4  style={{ color:"grey"  }} class="h4">
         Flow de votes
          </h4>


          <ReactECharts option={this.state.data.graph} />
          </Paper>
        </Grid>





      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <h4  style={{ color:"grey"  }} class="h4">
       Flow Provinces
        </h4>


        <ReactECharts option={this.state.data.graph} />
        </Paper>
      </Grid>
    </Grid>
      <Copyright sx={{ pt: 4 }} />
    </Container>
    </>
  )

  }


}


export default connect((state) => ({
  url: state.appstate.url,
  user: state.appstate.user,
  axios: state.appstate.axios,





}))(HomeView);
