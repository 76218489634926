import {reactLocalStorage} from 'reactjs-localstorage';
import axios from 'axios';
const url = "https://govote.genielabel.com";
//https://govote.genielabel.com : http://192.168.145.123
axios.defaults.baseURL = url


const initialState = {



  count: 0,
  url:url,
  user:{},
  axios:axios,

  archives:[]




};

var user = reactLocalStorage.getObject('mayele');

if (user) {
  initialState.user = user

    axios.defaults.headers.common = {'Authorization': `Bearer ${user.token}` }
}

function refresh(user) {
  console.log("refresh");
    axios.defaults.headers.common = {'Authorization': `Bearer ${user.token}`}
}
const counterReducer = (state = initialState, action = {}  ) => {
  switch (action.type) {

    case "LOGIN":
      return {
        ...state,
        info: action.data
      };

      case "REFRESH":
        return {
          ...state,
          info: refresh(action.data)
        };

    default:
      return state
  }
}

export default counterReducer
