import { useReducer,useCallback, useState } from 'react';


import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Alert,
  Box,
  Button,
  FormHelperText,
  Link,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@mui/material';
import { connect } from 'react-redux'
import axios from "axios";
import * as React from 'react';
import { styled } from '@mui/material/styles';
import {reactLocalStorage} from 'reactjs-localstorage';
import { Layout as AuthLayout } from '../layouts/auth/layout';
import ReactPhoneInput from 'react-phone-input-material-ui';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
const Page = (props) => {

  const [method, setMethod] = useState('email');
  const [error, setError] = useState('');
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [progress, updateProgress] = useState(0);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: '',
      id: '',
      phone: '',
      office: '',
      province: '',
      state: '',
      "candidat": "6548b4f294cae6556322f3ad",
      office: '',

      submit: null
    },
    validationSchema: Yup.object({
    name: Yup
        .string()

        .max(255)
        .required('Name is required'),
      phone: Yup
        .string()
        .max(255)
        .required('Phone is required')
    }),
    onSubmit: async (values, helpers) => {
console.log(values);



      setLoading(true)



values.candidat =  "65468b295bf592078123b270";
      props.axios.post('/admin/witnesses', values )
      .then( async response => {


      var res = response.data;
      if (res.error) {
          setError(res.message);


      }else {


        try {


  window.location = "/witness";
        } catch (err) {
          console.error(err);
        }



      }


      })
        .catch( error => {

      setError("Erreurs lors du traitement des données");
      setLoading(false)
      });




    }
  });


    const onFileChange = event => {



if (event.target.files[0]) {


 setPreview(URL.createObjectURL(event.target.files[0]))

         setFile(event.target.files[0])
       }

     };
  const handleMethodChange = useCallback(
    (event, value) => {
      setMethod(value);
    },
    []
  );



  return (
    <>

      <Box
        sx={{
          backgroundColor: 'background.paper',
          flex: '1 1 auto',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Box
          sx={{
            maxWidth: 550,
            px: 3,
            py: '100px',
            width: '100%'
          }}
        >
          <div>

  {loading &&

 <BorderLinearProgress variant="determinate" value={progress} />
}
              {!loading &&

                <form
                noValidate
                onSubmit={formik.handleSubmit}
              >
                <Stack spacing={3}>
                  <TextField
                    error={!!(formik.touched.name && formik.errors.name)}
                    fullWidth
                    helperText={formik.touched.name && formik.errors.name}
                    label="Nom complet"
                    name="name"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="name"

                    value={formik.values.name}
                  />


                  <TextField
                    error={!!(formik.touched.id && formik.errors.id)}
                    fullWidth
                    helperText={formik.touched.id && formik.errors.id}
                    label="Id National / CENI "
                    name="id"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}


                    value={formik.values.id}
                  />


                  <TextField
                    error={!!(formik.touched.phone && formik.errors.phone)}
                    fullWidth
                    helperText={formik.touched.phone && formik.errors.phone}
                    label="Téléphone"
                    name="phone"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="description"

                    value={formik.values.phone}
                  />




                  <TextField
                    error={!!(formik.touched.province && formik.errors.province)}
                    fullWidth
                    helperText={formik.touched.province && formik.errors.province}
                    label="Province "
                    name="province"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}


                    value={formik.values.province}
                  />


                  <TextField
                    error={!!(formik.touched.office && formik.errors.office)}
                    fullWidth
                    helperText={formik.touched.office && formik.errors.office}
                    label="Office "
                    name="office"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}


                    value={formik.values.office}
                  />




                  <TextField
                    error={!!(formik.touched.state && formik.errors.state)}
                    fullWidth
                    helperText={formik.touched.state && formik.errors.state}
                    label="District "
                    name="state"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}


                    value={formik.values.state}
                  />



                </Stack>

                {error && (

                  <Alert
                    color="secondary"
                    severity="error"
                    sx={{ mt: 3 }}
                  >
                    <div>

                    {error}
                    </div>
                  </Alert>
                )}
                <Button
                  fullWidth
                  size="large"
                  sx={{ mt: 3 }}
                  type="submit"
                  variant="contained"
                >
                  Soumettre
                </Button>


              </form>
            }


          </div>
        </Box>
      </Box>
    </>
  );
};





export default connect((state) => ({
  url: state.appstate.url,
  user: state.appstate.user,
  nav: state.appstate.nav,
axios: state.appstate.axios,
  counter: state.appstate.count,


}))(Page);
