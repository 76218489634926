import * as React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import {Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import moment from 'moment'
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
// Generate Order Data
function createData(id, date, name, shipTo, paymentMethod, amount) {
  return { id, date, name, shipTo, paymentMethod, amount };
}

function preventDefault(event) {
  event.preventDefault();
}




export default function Orders(props) {

  let history = useHistory();
  const [loading, setLoading] = React.useState(false);

  const handleOpen = (route) => {

  //setOpen(false)
  //history.push(route);
  };




      const downloadImage = ((url, name) => {
          setLoading(true);
  fetch(url)
    .then(resp => resp.blob())
    .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want
        a.download = name;
        document.body.appendChild(a);
        a.click();

        setLoading(false);
        //window.URL.revokeObjectURL(url);
    })
    .catch(() => alert('An error sorry'));})



  return (
    <React.Fragment>
      <Title>{props.title}</Title>
      <Table size="small">
        <TableHead>
          <TableRow>

            <TableCell>Nom</TableCell>
            <TableCell>Province</TableCell>
              <TableCell>Vote</TableCell>
              <TableCell>District</TableCell>
             <TableCell>Actions</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((row) => (
            <TableRow key={row.id}>


              <TableCell onClick={() => { handleOpen(`/authors/${row._id}`); }}>



                 <Stack direction="row" spacing={2}>
        <h6 style={{marginTop:20}}>{row.name}</h6>
    </Stack>


    </TableCell>



    <TableCell onClick={() => { handleOpen(`/authors/${row._id}`); }}>
<h6 style={{marginTop:20}}>{row.province}</h6>
</TableCell>


<TableCell onClick={() => { handleOpen(`/authors/${row._id}`); }}>
<h6 style={{marginTop:20}}>{row.vote}</h6>
</TableCell>



<TableCell onClick={() => { handleOpen(`/authors/${row._id}`); }}>
<h6 style={{marginTop:20}}>{row.office}</h6>
</TableCell>

<TableCell>
{row.vote && row.file &&
 <LoadingButton
          size="small"
          onClick={() => { downloadImage(row.file.secure_url,row.name) }}
          loading={loading}
          loadingIndicator="Chargement…"
          variant="outlined"
        >
          <span>Télécharger</span>
        </LoadingButton>
}
</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

    </React.Fragment>
  );
}
