
import React, { Component } from 'react';
import { Redirect, Route } from "react-router-dom";

import Login from './Pages/Auth'
import NavBar from "./Pages/Main";
import WitnessAdd from "./Pages/WitnessAdd";
import Home from "./Pages/Home";
import Witness from "./Pages/Witness";


 const AppRoute = (
  <>

<Route exact path="/login" component={Login} />

<RouteWrapper exact path="/" component={Home} />
<RouteWrapper exact path="/witness" component={Witness} />

<RouteWrapper exact path="/witness/add" component={WitnessAdd} />


     </>
   );


   function RouteWrapper({ component: Component, layout: Layout, ...rest }) {


        return (
          <Route
            {...rest}
            render={props => (
              <NavBar {...props}>
                <Component {...props}     />


              </NavBar>
            )}
          />
        );

      }

export default AppRoute;
