import { useReducer,useCallback, useState } from 'react';


import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Alert,
  Box,
  Button,
  FormHelperText,
  Link,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@mui/material';
import { connect } from 'react-redux'
import axios from "axios";
import {reactLocalStorage} from 'reactjs-localstorage';
import { Layout as AuthLayout } from '../layouts/auth/layout';

const Page = (props) => {

  const [method, setMethod] = useState('email');
  const [error, setError] = useState('');

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      submit: null
    },
    validationSchema: Yup.object({
      email: Yup
        .string()
        .email('Must be a valid email')
        .max(255)
        .required('Email is required'),
      password: Yup
        .string()
        .max(255)
        .required('Password is required')
    }),
    onSubmit: async (values, helpers) => {


      const form = {email:values.email, password:values.password}

      props.axios.post('/api/auth/login', form )
      .then( async response => {


      var res = response.data;
      if (res.error) {
          setError(res.message);

      }else {


        try {
          reactLocalStorage.setObject('mayele', response.data);



     const { dispatch } = props;
      dispatch({
          type: "LOGIN",
          data:response.data,

        });

  window.location = "/";
        } catch (err) {
          console.error(err);
        }



      }


      })
        .catch( error => {


      });


    }
  });

  const handleMethodChange = useCallback(
    (event, value) => {
      setMethod(value);
    },
    []
  );



  return (
    <>

      <Box
        sx={{
          backgroundColor: 'background.paper',
          flex: '1 1 auto',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Box
          sx={{
            maxWidth: 550,
            px: 3,
            py: '100px',
            width: '100%'
          }}
        >
          <div>
            <Stack
              spacing={1}
              sx={{ mb: 3 }}
            >
              <Typography variant="h4">
                Login
              </Typography>

            </Stack>
            <Tabs
              onChange={handleMethodChange}
              sx={{ mb: 3 }}
              value={method}
            >
              <Tab
                label="Email"
                value="email"
              />

            </Tabs>
            {method === 'email' && (
              <form
                noValidate
                onSubmit={formik.handleSubmit}
              >
                <Stack spacing={3}>
                  <TextField
                    error={!!(formik.touched.email && formik.errors.email)}
                    fullWidth
                    helperText={formik.touched.email && formik.errors.email}
                    label="Email Address"
                    name="email"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="email"

                    value={formik.values.email}
                  />
                  <TextField
                    error={!!(formik.touched.password && formik.errors.password)}
                    fullWidth
                    helperText={formik.touched.password && formik.errors.password}
                    label="Password"
                    name="password"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="password"
                    value={formik.values.password}
                  />
                </Stack>

                {error && (

                  <Alert
                    color="secondary"
                    severity="error"
                    sx={{ mt: 3 }}
                  >
                    <div>

                    {error}
                    </div>
                  </Alert>
                )}
                <Button
                  fullWidth
                  size="large"
                  sx={{ mt: 3 }}
                  type="submit"
                  variant="contained"
                >
                  Continue
                </Button>

                <Alert
                  color="primary"
                  severity="info"
                  sx={{ mt: 3 }}
                >
                  <div>
                    You can contact <b>hello</b> and password <b>Password123!</b>
                  </div>
                </Alert>
              </form>
            )}

          </div>
        </Box>
      </Box>
    </>
  );
};

Page.getLayout = (page) => (
  <AuthLayout>
    {page}
  </AuthLayout>
);



export default connect((state) => ({
  url: state.appstate.url,
  user: state.appstate.user,
  nav: state.appstate.nav,
axios: state.appstate.axios,
  counter: state.appstate.count,


}))(Page);
