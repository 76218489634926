import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import AssignmentIcon from '@mui/icons-material/Assignment';
import HeadsetIcon from '@mui/icons-material/Headset';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
export const mainListItems = (
  <React.Fragment>
    <ListItemButton onClick={()=>   window.location = "/" }>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Global Report " />
    </ListItemButton>
    <ListItemButton onClick={()=>   window.location = "/witness" }>
      <ListItemIcon>
        <SwitchAccountIcon/>
      </ListItemIcon>
      <ListItemText primary="Temoins" />
    </ListItemButton>



  </React.Fragment>
);

export  const secondaryListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
  Se deconnecter
    </ListSubheader>

  </React.Fragment>
);
