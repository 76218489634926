

import React from 'react';
//import axios from 'axios';


import axios from 'axios';
import { connect } from "react-redux";

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Witnesses from './Components/Witnesses';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

class HomeView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
then:false,
active:0,
data:[],
modal:true


    };


  }


  componentDidMount (){

  this.getdata();
  }




   getdata = () => {
     console.log("calling");
     this.setState({then: true});

         this.props.axios.get('/admin/witnesses' )
       .then( response => {

console.log(response.data);
         this.setState({then: false});
       this.setState({data: response.data})



  this.setState({then: false});
       })
         .catch( error => {
         //*.log(error);
           this.setState({then: false});

       });




   };




  render() {

  return (

    <>


    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>

      <Grid container spacing={3}>


        {/* Recent Orders */}
        <Grid item xs={12}>

        <Stack direction="row" spacing={2}>
              <Button variant="contained" color="success" onClick={()=>   window.location = "/witness/add" }>Ajouter</Button>
            </Stack>
<br/>

          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <Witnesses title={"Témoins"} data={this.state.data} />
          </Paper>
        </Grid>
      </Grid>

    </Container>
    </>
  )

  }


}


export default connect((state) => ({
  url: state.appstate.url,
  user: state.appstate.user,
  axios: state.appstate.axios,





}))(HomeView);
