import * as React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Title from './Title';
import { AnimatedCounter } from  'react-animated-counter';
function preventDefault(event) {
  event.preventDefault();
}

export default function Deposits(props) {
  return (
    <React.Fragment>

      <h4  style={{ color:"grey"  }} class="h4">
  {props.title}
      </h4>
      <Typography component="p" variant="h3" style={{fontWeight:"bold"}}>


         <AnimatedCounter value={props.amount} color="black" fontSize="40px" />
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
        {new Date().toLocaleDateString("de-DE")}
      </Typography>

    </React.Fragment>
  );
}
